import './src/styles/graenvangur.scss'

import React from 'react'

import { ModalProvider } from '@islandsstofa/react'
import { SearchProvider } from '@src/context/searchContext'
import { MobileMenuProvider } from '@src/context/mobileMenuContext'

const wrapRootElement = ({ element }) => (
  <SearchProvider>
    <ModalProvider>
      <MobileMenuProvider>{element}</MobileMenuProvider>
    </ModalProvider>
  </SearchProvider>
)

export { wrapRootElement }
// Hard reload pages when service worker changes, to ensure user isn't being served old code
export function onServiceWorkerUpdateReady () {
  window.location.reload(true)
}
